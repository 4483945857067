import app from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';


const config = {
    apiKey: "AIzaSyBHNiSPcR2bkA5ai27IjdfmWvvI4bK2RLA",
    authDomain: "michael-site-f66ef.firebaseapp.com",
    databaseURL: "https://michael-site-f66ef.firebaseio.com",
    projectId: "michael-site-f66ef",
    storageBucket: "michael-site-f66ef.appspot.com",
    messagingSenderId: "421109682015",
    appId: "1:421109682015:web:b418f7d1e2ceff7b"
}

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.firestore = app.firestore();
        this.storage = app.storage().ref();

    }

    

    // *** Auth API ***
    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();


    // *** Upload API ***

    doBeatUpload = (file) =>
        this.storage.child('beats').child(file.name).put(file);


    doCreateBeatDoc = (name, genre, downloadUrl, colorCode) =>
        this.firestore.collection('beats').add({
            name: name,
            genre: genre,
            url: downloadUrl,
            colorCode: colorCode,
        });

        


    // *** Get Data Api ***

    //gets all docs from beats collection

    doGetAllActiveBeats = () => 
        this.firestore.collection('beats').where("active", "==", true).get();
    

    doGetAllBeats = () =>
        this.firestore.collection('beats').get();


    doGetSingleBeat = (id) => 
        this.firestore.collection('beats').doc(id).get();



    // changes active state
    changeActiveBeatState = (active, id) => 
        this.firestore.collection('beats').doc(id).set({
            active: !active
        }, {merge: true}); 


    doDeleteBeat = (id) => {
        var r = confirm('Are you sure you want to delete?');
        if (r == true){
            this.firestore.collection('beats').doc(id).delete();
        } else {
            return
        }
    }

    // doDeleteBeat = (id) => 
    //     this.firestore.collection('beats').doc(id).delete();

        //snapshot listener
    doListenToAllBeats = () => 
        this.firestore.collection('beats');


    


}

export default Firebase;