import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import '../css/pages/mobileAllMusic.css'


// COMPONENTS
import MobileMusicItem from '../components/mobileMusicItem';
import MobilePageNav from '../components/mobilePageNav';
import MobilePageHeader from '../components/mobilePageHeader';


import { observer, inject } from 'mobx-react';

@inject('PlayerStore')
@observer
 class MobileAllMusic extends Component {
  constructor (props) {
    super(props)
    this.state = { 
        folderClicked: false,
        songs:null,

    }
  }

  componentWillMount(){

  }

  componentDidMount(){
    // window.scrollTo(0,0);
  }

  componentWillUnmount(){
    
  }

  handleFolderClicked = () => {
    this.setState({
      folderClicked: !this.state.folderClicked
    });
  }

  navHome = () => {
      this.props.history.push('/');
  }



  render() {
    const {PlayerStore} = this.props;

    const musicItems = this.props.songs ? this.props.songs.map((item, i) => <MobileMusicItem key={i} url={item.url} colorCode={item.colorCode} name={item.name} genre={item.genre}/>) : null;


    return (
      <div className="mobile-all-music-container">
            <MobilePageNav currentPageName="All Music" navAction={this.navHome} pageName="Home"/>
            <MobilePageHeader pageTitle="All Music"/>
            <div className="mobile-music-items-container">{musicItems}</div>
      </div>
    );
  }
}

export default withRouter(MobileAllMusic);
