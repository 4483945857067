import {observable, action} from 'mobx';
import {create,persist} from 'mobx-persist';
import { serializable, list, object } from 'serializr';
import MusicItem from '../components/musicItem';


class PlayerStore{
     @observable genres = [];
     @observable musicArray = null;
     @observable songId = null;
     @observable shareUrl = null;
     @observable fileName = null;
     @observable fileUrl = null;
     @observable colorCode = '#fff';
     @observable playing = null;
    // @observable file = null;

    @action handleItemClick(id, name, url, colorCode){
        this.songId = id;
        this.fileName = name;
        this.fileUrl = url;
        this.colorCode = colorCode;
        this.playing = true;
        this.shareUrl = 'michael-site-f66ef.firebaseapp.com/song/' + id;
        // this.onPlay(true);
    };


    @action handleSongPageLoad(id, name, url, colorCode){
        this.songId = id;
        this.fileName = name;
        this.fileUrl = url;
        this.colorCode = colorCode;
        this.shareUrl = 'michael-site-f66ef.firebaseapp.com/song/' + id;

    }



    @action setMusicArray(array){
        console.log(array); 
        var newArray = [];
        newArray.concat(array);

        this.musicArray = newArray;
        
        // musicArray.concat(array);
    }


    @action onPlay(){
        console.log('mobx onplay');
        this.playing = true;
    };


    @action onPause(){
        console.log('mobx onpause');
        this.playing = false;
    };

    @action userExitedSite(){
        console.log('user exited site');
        this.playing = flase;
    }


    // @observable url: null;
    // @observable pip: false;
    // @observable playing: true;
    // @observable controls: false;
    // @observable light: false;
    // @observable volume: 0.8;
    // @observable muted: false;
    // @observable played: 0;
    // @observable loaded: 0;
    // @observable duration: 0;
    // @observable playbackRate: 1.0;
    // @observable loop: false;
    


    



    // @action onPause = () => {
    //     console.log('onPause')
    //     this.setState({ playing: false })
    //   }

    // @action onSeekMouseDown = (e) => {
    //     this.setState({ seeking: true })
    //   }

    // @action  onSeekChange = (e) => {
    //     this.setState({ played: parseFloat(e.target.value) })
    //   }

    // @action onSeekMouseUp = (e) => {
    //     this.setState({ seeking: false })
    //     this.player.seekTo(parseFloat(e.target.value))
    //   }

    // @action onProgress = (state) => {
    //     console.log('onProgress', state)
    //     // We only want to update time slider if we are not currently seeking
    //     if (!this.state.seeking) {
    //       this.setState(state)
    //     }
    //   }

    // @action onEnded = () => {
    //     console.log('onEnded')
    //     this.setState({ playing: this.state.loop })
    //   }

    // @action  onDuration = (duration) => {
    //     console.log('onDuration', duration)
    //     this.setState({ duration })
    //   }



}

export default new PlayerStore();

