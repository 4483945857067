import React, { Component } from 'react';
import folderIcon from '../mac-folder-icon.png';
import firebase from 'firebase';
import '../css/components/macWindow.css';


import close from '../close.svg';
import expand from '../expand.svg';
import minimize from '../minimize.svg';

import MusicItem from '../components/musicItem';
import DesktopMusicIcon from '../components/DesktopMusicIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMusic, faUser } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';


// const songs = [{id: 0, name: 'Beat 1',},
//                 {id: 0, name: 'Beat 1',},
//                     {id: 0, name: 'Beat 1',},
//                         {id: 0, name: 'Beat 1',},
//                             {id: 0, name: 'Beat 1',},
//                                 {id: 0, name: 'Beat 1',},
//                                     {id: 0, name: 'Beat 1',},
//                                         {id: 0, name: 'Beat 1',},
//                                             {id: 0, name: 'Beat 1',},
//                                                 {id: 0, name: 'Beat 1',},
//                                                     {id: 0, name: 'Beat 1',},
//                                                         {id: 0, name: 'Beat 1',},
//                                                             {id: 0, name: 'Beat 1',},
//                                                                 {id: 0, name: 'Beat 1',},
//                                                                     {id: 0, name: 'Beat 1',},
//                                                                         {id: 0, name: 'Beat 1',},
//                                                                             {id: 0, name: 'Beat 1',},
//                                                                                 {id: 0, name: 'Beat 1',},
//                                                                                     {id: 0, name: 'Beat 1',},
//                                                                                         {id: 0, name: 'Beat 1',},
//                                                                                             {id: 0, name: 'Beat 1',},
//                                                                                                 {id: 0, name: 'Beat 1',},
//                                                                                                     {id: 0, name: 'Beat 1',},
//                                                                                                         {id: 0, name: 'Beat 1',},];




export default class MacWindow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logoClicked: false,
            songs: null,
            music: null,
            selectedView: 0,

        }
    }

    componentDidMount() {

    }

    componentWillMount() {
    }



    componentWillUnmount() {
    }




    render() {




        // const musicItems = this.props.songs ? this.props.songs.map((item, i) => <MusicItem key={i} url={item.url} name={item.name} />) : null;
        const musicItems = this.props.songs ? this.props.songs.map((item, i) => <DesktopMusicIcon key={i} url={item.url} name={item.name} genre={item.genre} id={item.id} colorCode={item.colorCode}/>) : null;
        const musicIcon = <FontAwesomeIcon icon={faMusic} className="desktop-icon" />;
        const userIcon = <FontAwesomeIcon icon={faUser} className="desktop-icon" />;
        const mailIcon = <FontAwesomeIcon className="desktop-contact-icon" icon={faEnvelope} />;
        const twitterIcon = <FontAwesomeIcon className="desktop-contact-icon" icon={faTwitter} />;
        const instaIcon = <FontAwesomeIcon className="desktop-contact-icon" icon={faInstagram} />;


        const DisplayWindow = () => {
            if (this.state.selectedView == 0) {
                return (
                    <div className="window-view-content">
                        {musicItems}
                    </div>
                );
            } else if (this.state.selectedView == 1) {
                return (
                    <div className="window-view-content_contact">
                        <a href="mailto:mikeydbeatz@gmail.com">{mailIcon}</a>
                        <a href="https://www.twitter.com/MichaelScaros" target="_blank">{twitterIcon}</a>
                        <a href="https://www.instagram.com/michaelscaros/" target="_blank">{instaIcon}</a>

                    </div>
                );
            } else {
                return (
                    <div className="window-view-content">
                        {musicItems}
                    </div>
                );
            }
        };

        // const musicItems = this.state.songs ? this.state.songs.map((item, i) => <MusicItem key={i} url={item.url} name={item.name}/>) : null;


        return (
            <div style={this.props.folderClicked ? { display: 'flex' } : { display: 'none' }} className="mac-window-component">
                <div className="window-bar">
                    <div className="window-controls-container">
                        <div className="window-control window-control__close">
                            <svg x="0px" y="0px" width="10px" height="10px" viewBox="0 0 20 20" data-radium="true" style={{ opacity: 1 }}>
                                <polygon fill="#4d0000" points="15.9,5.2 14.8,4.1 10,8.9 5.2,4.1 4.1,5.2 8.9,10 4.1,14.8 5.2,15.9 10,11.1 14.8,15.9 15.9,14.8 11.1,10 "></polygon>
                            </svg>
                        </div>
                        {/* <div className="window-control window-control__minimize">
                    <svg x="0px" y="0px" width="10px" height="10px" viewBox="0 0 20 20" data-radium="true" style={{opacity: 1}}>
                        <rect fill="#995700" x="2.4" y="9" width="15.1" height="2"></rect>
                    </svg>
                </div>
                <div className="window-control window-control__expand">
                    <svg x="0px" y="0px" width="10px" height="10px" viewBox="0 0 20 20" data-radium="true" style={{opacity: 1}}>
                        <path fill="#006400" d="M5.3,16H13L4,7v7.7C4.6,14.7,5.3,15.4,5.3,16z"></path><path fill="#006400" d="M14.7,4H7l9,9V5.3C15.4,5.3,14.7,4.6,14.7,4z"></path>
                    </svg>
                </div> */}
                    </div>
                    <div className="window-title-container"><p>Mike Made This Beat</p></div>
                    <div className="window-controls-container"></div>
                </div>


                <div className="window-view">

                    <div className="window-view-sidebar">
                        <div onClick={() => this.setState({ selectedView: 0 })} className={this.state.selectedView == 0 ? "window-sidebar-button window-sidebar-button_active" : "window-sidebar-button"}>
                            {musicIcon}
                            <p className="window-sidebar-button-text">All Music</p>
                        </div>

                        <div onClick={() => this.setState({ selectedView: 1 })} className={this.state.selectedView == 1 ? "window-sidebar-button window-sidebar-button_active" : "window-sidebar-button"}>
                            {userIcon}
                            <p className="window-sidebar-button-text">Contact</p>
                        </div>
                    </div>

                    {/* <div className="window-view-content">
                        {musicItems}
                    </div> */}

                    <DisplayWindow/>

                </div>


            </div>
        );
    }
}


