import React, { Component } from 'react';
// import folderIcon from '../mac-folder-icon.png';
import musicIcon from '../music-icon-mac.png';
import {inject, observer} from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'

@inject('PlayerStore')
@observer
export default class MusicItem extends Component {
  constructor (props) {
    super(props)
    this.state = { 
      
    }
  }

  componentWillMount(){

  }

  componentDidMount(){

  }

  componentWillUnmount(){
    
  }


  musicItemClicked = () => {
    console.log('cliked');
  }

  render() {
    const {PlayerStore} = this.props;
    const playButton = <FontAwesomeIcon className="music-item-play-button" icon={faPlay} />;
    const name = this.props.name;
    const url = this.props.url;
    

    return (
      <div onClick={() => PlayerStore.handleItemClick(name, url)} className="music-item-component">
        <div className="music-item-component-image-container">
          <img src={musicIcon} alt="logo"></img>
          <div className="music-item-play-overlay-container">
            {playButton}
          </div>
        </div>
        <div className="music-item-component-text-container"><p className="music-item-name-text">{this.props.name}</p></div>
      </div>
    );
  }
}


