import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import '../../css/pages/login.css';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';




const LogInPage = () => (
  <div className="login-page">
    <LogInForm/>
  </div>
);


const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};


class LogInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.ADMIN);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email"
        />

        <input
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />

        <button onClick={this.handleSignIn} className="login-button">Beam me up</button>

      </form>
    );


  }


}


const LogInForm = compose(
  withRouter,
  withFirebase,
)(LogInFormBase);

export default LogInPage;
export {LogInForm};
