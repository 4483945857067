import React, { Component } from 'react';
import folderIcon from '../mac-folder-icon.png';



export default class FolderComponent extends Component {
  constructor (props) {
    super(props)
    this.state = { 

    }
  }

  componentWillMount(){

  }

  componentDidMount(){

  }

  componentWillUnmount(){
    
  }



  render() {
    return (
      <div style={this.props.folderClicked ? {display: 'none'} : {display: 'flex'}} onClick={this.props.handleFolderClicked} className="folder-component">
        <div className="folder-component-image-container"><img src={folderIcon} className="App-logo" alt="logo"/></div>
        <div className="folder-component-text-container"><p className="folder-name-text">beats</p></div>
      </div>
    );
  }
}


