import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import '../css/components/player.css';


import ReactPlayer from 'react-player'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faMusic, faForward } from '@fortawesome/free-solid-svg-icons'





@inject('PlayerStore')
@observer
export default class Player extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // url: null,
      // pip: false,
      // playing: true,
      // controls: false,
      // light: false,
      // volume: 0.8,
      // muted: false,
      // played: 0,
      // loaded: 0,
      // duration: 0,
      // playbackRate: 1.0,
      // loop: false

      loop: true,
      playing: false,
      playbackRate: 1,
      volume: 1,
      played: 0,
      seeking: false,




    }
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }


  playPause = () => {
    this.setState({ playing: !this.state.playing })
  }
  stop = () => {
    this.setState({ url: null, playing: false })
  }

  onPause = () => {
    console.log('onPause')
    this.setState({ playing: false })
  }

  onPlay = () => {
    console.log('onPlay')
    this.setState({ playing: true })
  }

  //   onEnded = () => {
  //     console.log('onEnded')
  //     this.setState({ playing: this.state.loop })
  //   }

  onDuration = (duration) => {
    console.log('onDuration', duration)
    this.setState({ duration })
  }

  onProgress = state => {
    console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }
  }

  onSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  }

  onSeekMouseDown = (e) => {
    this.setState({ seeking: true })
  }

  onSeekMouseUp = (e) => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  ref = player => {
    this.player = player
  }

  render() {

    const { PlayerStore } = this.props;
    const viewExpanded = !PlayerStore.fileName ? false : true;

    const PlayPauseControl = () => {
      if (this.state.playing) {
        return (<FontAwesomeIcon className="play-pause-control active-icon-color" onClick={() => this.setState({ playing: !this.state.playing })} icon={faPause} />);
      } else {
        return (<FontAwesomeIcon className={!PlayerStore.fileName ? "play-pause-control inactive-icon-color" : "play-pause-control active-icon-color"} onClick={() => this.setState({ playing: !this.state.playing })} icon={faPlay} />);
      }
    }

    const ForwardControl = () => {
      return (
        <FontAwesomeIcon className="play-pause-control active-icon-color" icon={faForward} />
      );
    }


    const AlbumCoverDisplay = () => {
      if (viewExpanded) {
        return (
          <div className="player-music-album-cover-container_active">
            <div className="player-music-album-cover">
              {musicIcon}
            </div>
          </div>
        );
      } else {
        return (
          <div className="player-music-album-cover-container">
            <div className="player-music-album-cover">
              {musicIcon}
            </div>
          </div>
        );
      }
    }

    const musicIcon = <FontAwesomeIcon className="music-player-music-icon" icon={faMusic} />;
    const songTitle = !PlayerStore.fileName ? "Not Playing" : PlayerStore.fileName;





    // const DisplayPlayer = () => {
    //   if(!PlayerStore.fileName){
    //     return (


    //     );


    //   }


    // }





    return (
      <div className={viewExpanded ? "player-container player-container_show" : "player-container player-container_hide"}>
        <ReactPlayer
          ref={this.ref}
          className="react-player-hidden"
          url={PlayerStore.fileUrl}
          // playing={this.state.playing}
          playing={this.state.playing}
          // loop={this.state.loop}

          onPlay={this.onPlay}
          onPause={this.onPause}
          onProgress={this.onProgress}
          onDuration={this.onDuration}

          playbackRate={1}
          volume={1}
          controls={false}

        />




        {/* <div className={viewExpanded ? "player-music-album-cover-container_active" : "player-music-album-cover-container"}>
          <div className="player-music-album-cover">
            {musicIcon}
          </div>
        </div> */}

        <AlbumCoverDisplay/>


        <div className="player-music-name-container">
          <h4 className="player-music-song-title">{songTitle}</h4>
        </div>


        <div className="player-music-controls-container">
          <PlayPauseControl />
        </div>





      </div>
    );
  }
}




{/* <div className="player-seeker-container">
            <input
              type='range' min={0} max={1} step='any'
              
              value={this.state.played}
              onMouseDown={this.onSeekMouseDown}
              onChange={this.onSeekChange}
              onMouseUp={this.onSeekMouseUp}
            />
        
        </div> */}