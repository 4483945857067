import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import '../../../css/components/MobilePlayer/components/barInfoContainer.css';





import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faMusic, faForward } from '@fortawesome/free-solid-svg-icons'





@inject('PlayerStore')
@observer
export default class ArtworkContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fullscreen: false,


        }
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }




    render() {

        const { PlayerStore } = this.props;


        const PlayPauseControl = () => {
            if (this.props.playing) {
                return (<FontAwesomeIcon className="bar-info-control-icon"  icon={faPause} />);
            } else {
                return (<FontAwesomeIcon className="bar-info-control-icon"  icon={faPlay} />);
            }
        }



        const musicIcon = <FontAwesomeIcon className={this.props.fullscreen ? "bar-info-container bar-info-container_show" : "bar-info-container bar-info-container_hidden"} icon={faMusic} />




        return (
            <div className={this.props.fullscreen ? "bar-info-container bar-info-container_show" : "bar-info-container bar-info-container_hidden"}>
                <h3>{this.props.songTitle}</h3>
                <div onClick={this.props.playing ? this.props.onPause : this.props.onPlay} className="button-container"><PlayPauseControl/></div>
            </div>
        );
    }
}

