import React, { Component } from 'react';
import '../../css/pages/manage.css';
import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import { Table, Switch, Button } from "flwww";


class ManagePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            songs: null,
        }
    }


    componentDidMount() {
       
        this.props.firebase.doListenToAllBeats()
            .onSnapshot((snapshot) => {
                var songArray = [];
                snapshot.forEach((doc) => {
                    var songObj = new Object(doc.data());
                    songObj.id = doc.id;
                    songObj.Name = doc.data().name;
                    songObj.Genre = doc.data().genre;
                    songObj.URL = doc.data().url;
                    songObj.DateCreated = doc.data().dateCreated;
                    songObj.Active = <Switch name="switchVal" onChange={() => this.props.firebase.changeActiveBeatState(doc.data().active, doc.id)} checked={ doc.data().active } />
                    songObj.Delete = <Button onClick={() => this.props.firebase.doDeleteBeat(doc.id)} type="danger">Delete</Button>
                    songArray.push(songObj);
                });
                this.setState({ songs: songArray }, function () {});

            }, function(error){
                console.log(error);
            });


    }


    render() {

        // const columns = ["Name", "Genre", "URL", "Active"];
        const columns = ["Name", "Genre", "Active", "Delete"];



        const TableView = () => {
            if (!this.state.songs) {
                return (<h1>loading</h1>);
            } else {
                return (
                    <Table
                        bordered
                        columns={columns}
                        rows={this.state.songs} />
                );
            }
        }

        
        return (
            <div className="manage-page">
                <TableView/>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ManagePage);

