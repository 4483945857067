import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase/index';
import '../../css/pages/song.css';
import { Icon } from "flwww";




import { compose } from 'recompose';


import MobilePageNav from '../../components/mobilePageNav';





import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faMusic, faForward } from '@fortawesome/free-solid-svg-icons'
import Player from '../player';






@inject('PlayerStore')
@observer
class Song extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayOverlay: true,
    }
  }

  componentWillMount() {

  }

  componentDidMount() {
    const { songId } = this.props.match.params;
    this.playLinkedSong(songId);
    // console.log('songID: ' + songId);
  }

  componentWillUnmount() {

  }

  playLinkedSong = (id) => {
    if (id === undefined) {
      console.log('song id undefined')
    } else {
      this.props.firebase.doGetSingleBeat(id)
        .then((doc) => {
          var song = new Object();
          song.id = doc.id;
          console.log('song id ' + song.id);
          song.name = doc.data().name;
          song.url = doc.data().url;
          song.colorCode = doc.data().colorCode;
          return song
          // this.props.PlayerStore.handleItemClick(name, url, colorCode)
        })
        .then((song) => {
          console.log('song: ' + song);
          // this.props.PlayerStore.handleItemClick(song.id, song.name, song.url, song.colorCode)
          this.props.PlayerStore.handleSongPageLoad(song.id, song.name, song.url, song.colorCode);

        })
        .catch(error => console.log(error));
    }
  }


  navHome = () => {
    this.props.history.push('/');
  }


  hideOverlay = () => {
    console.log('hideoverlay');
    this.setState({
      displayOverlay: false
    });
  }




  render() {

    const { PlayerStore } = this.props;




    const SongPageIcon = () => {
      if (!PlayerStore.fileName) {
        return (
          <Icon type="loader" />
        );
      } else {
        return (
          <FontAwesomeIcon className="song-music-icon" icon={faMusic} />
        );
      }
    }






    return (
      <div className="song-container">
          <MobilePageNav currentPageName="" navAction={this.navHome} pageName="Home" />

          <div style={{ backgroundColor: PlayerStore.colorCode }} className="song-cover">
            <SongPageIcon/>
            <div style={this.state.displayOverlay ? {display: 'flex'} : {display: 'none'} } onClick={() => {PlayerStore.onPlay(); this.hideOverlay()}} className="song-cover_overlay">
              <FontAwesomeIcon  className="song-cover-play-button" color="white" icon={faPlay}/>
            </div>
          </div>

          <h3 className="song-title">{PlayerStore.fileName}</h3>
          <h4 className="song-genre"></h4>
      </div>
    );
  }
}


export default compose(withRouter, withFirebase)(Song);

