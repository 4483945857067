import React, { Component } from 'react';
import firebase from "firebase";
import ReactPlayer from 'react-player'
import '../css/components/mobilePageHeader.css';





import { observer, inject } from 'mobx-react';

@inject('PlayerStore')
@observer
export default class MobilePageHeader extends Component {
  constructor (props) {
    super(props)
    this.state = { 
        folderClicked: false,
        songs:null,

    }
  }

  componentWillMount(){

  }

  componentDidMount(){
    
  }

  componentWillUnmount(){
    
  }


  handleFolderClicked = () => {
    this.setState({
      folderClicked: !this.state.folderClicked
    });
  }



  render() {


    return (
      <div className="mobile-page-header">
        <h1>{this.props.pageTitle}</h1>
      </div>
    );
  }
}


