import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import '../../../css/components/MobilePlayer/components/fullScreenControls.css';
import 'react-rangeslider/lib/index.css';
import { Button, message, Icon } from "flwww";
import { CopyToClipboard } from 'react-copy-to-clipboard';


import Duration from './Duration';
import { Range } from 'react-range';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faForward, faBackward } from '@fortawesome/free-solid-svg-icons'



@inject('PlayerStore')
@observer
export default class FullScreenControls extends Component {
    constructor(props) {
        super(props)
        this.state = {


        }
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }


    copyLink = () => {
        message("Link copied to clipboard!", "success");
    }


    render() {

        const { PlayerStore } = this.props;

        // const shareUrl = 'michael-site-f66ef.firebaseapp.com/song/' + PlayerStore.songId + '';


        const PlayPauseControl = () => {
            if (this.props.playing) {
                return (<FontAwesomeIcon className="full-screen-control-icon" onClick={this.props.onPause} icon={faPause} />);
            } else {
                return (<FontAwesomeIcon className="full-screen-control-icon" onClick={this.props.onPlay} icon={faPlay} />);
            }
        }

        const ForwardControl = () => {
            return (
                <FontAwesomeIcon className="full-screen-control-icon" onClick={() => console('clicked')} icon={faForward} />
            );
        }

        const BackwardControl = () => {
            return (
                <FontAwesomeIcon className="full-screen-control-icon" onClick={() => console('clicked')} icon={faBackward} />
            );
        }


        return (
            <div className={this.props.fullscreen ? "full-screen-controls full-screen-controls_show" : "full-screen-controls full-screen-controls_hidden"}>
                <div style={this.props.fullscreen ? { opacity: 1 } : { opacity: 0 }} className="full-screen-seeker-container">
                    {/* <InputRange
                        step='any'
                        maxValue={1}
                        minValue={0}
                        value={this.props.played}
                        onChange={this.props.onSeekChange}
                        onChangeComplete={value => console.log(value)}


                        
                    /> */}

                    <input
                        className="time-range-slider"
                        type='range'
                        min={0}
                        max={1}
                        step='any'
                        value={this.props.played}
                        onMouseDown={(e) => this.props.onSeekMouseDown(e)}
                        onChange={(e) => this.props.onSeekChange(e)}
                        onMouseUp={(e) => this.props.onSeekMouseUp(e)}
                    />
                </div>

                <div style={this.props.fullscreen ? { opacity: 1 } : { opacity: 0 }} className="full-screen-timing-container">
                    <div className="container">
                        <p style={{ marginBottom: 0, marginTop: 0, color: "#757778" }}><Duration seconds={this.props.duration * this.props.played} /></p>
                        <p style={{ marginBottom: 0, marginTop: 0, color: "#757778" }}><Duration seconds={this.props.duration * (1 - this.props.played)} /></p>
                    </div>
                </div>


                <div style={this.props.fullscreen ? { opacity: 1 } : { opacity: 0 }} className="full-screen-name-container">
                    <h3 className="full-screen-name-container_name">{this.props.songTitle}</h3>
                </div>





                <div style={this.props.fullscreen ? { opacity: 1 } : { opacity: 0 }} className="full-screen-buttons-container">
                    {/* <BackwardControl /> */}
                    <PlayPauseControl />
                    {/* <ForwardControl /> */}
                </div>


                <div style={this.props.fullscreen ? { opacity: 1 } : { opacity: 0 }} className="full-screen-share-container">
                    <CopyToClipboard text={PlayerStore.shareUrl} onCopy={() => message("Link copied to clipboard!", "success")}>
                        <span style={{ cursor: 'pointer', color: '#f62a54' }}>
                            <span className="full-screen-share-icon-container" ><Icon type="login" size="40px" color="#f62a54" /></span>
                            Share
                        </span>
                    </CopyToClipboard>
                </div>


            </div>
        );
    }
}

