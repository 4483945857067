import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Player from '../player';
import MobilePlayer from '../MobilePlayer/index';







import HomePage from '../Home';
import UploadPage from '../Upload';
import AdminPage from '../Admin';
import LoginPage from '../LogIn';
import ManagePage from '../Manage';
import ContactPage from '../Contact';
import SongPage from '../Song';


import MobileHomePage from '../../pages/mobileHome';
import MobileAllMusic from '../../pages/mobileAllMusic';
import MobileGenresPage from '../Genres/index';





import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';


@observer
@inject('PlayerStore')
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            songs: null,
            genres: null,


        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);


        this.props.firebase.doGetAllActiveBeats()
            .then((querySnapshot) => {
                var songArray = [];
                var genreArray = []; // array that will hold genre of each active song

                querySnapshot.forEach((doc) => {
                    var songObj = new Object();
                    songObj.id = doc.id;
                    songObj.name = doc.data().name,
                    songObj.genre = doc.data().genre;
                    songObj.dateCreated = doc.data().dateCreated;
                    songObj.active = doc.data().active;
                    songObj.url = doc.data().url;
                    songObj.colorCode = doc.data().colorCode;
                    songArray.push(songObj);
                    genreArray.push(songObj.genre); // push genre values
                });

                const uniqueGenreSet = new Set(genreArray); // remove duplicate values
                const activeGenreArray = [...uniqueGenreSet]; // convert set back to array

                this.setState({ songs: songArray, genres: activeGenreArray });


            })
            .catch(error => console.log(error));



    }


    componentWillMount() {

    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    playLinkedSong = (id) => {
        if(id == 'undefined'){
            console.log('song id undefined')
        } else {
            this.props.firebase.doGetSingleBeat(id)
                .then((doc) => {
                    const id = doc.id;
                    const name = doc.data().name;
                    const url = doc.data().url;
                    const colorCode = doc.data().colorCode;
                    PlayerStore.handleItemClick(id, name, url, colorCode)
                }).catch(error => console.log(error));
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        // const OptimizedRoutes = () => {
        //     if(this.state.width == 0){
        //         // return loading
        //         return(
        //             <div><h1>loading</h1></div>
        //         );


        //     } else if(this.state.width <= 600){
        //         // return mobile

        //         return (
        //             <Route exact path={ROUTES.HOME} component={MobileHomePage} />
        //         );

        //     } else {
        //         // return desktop
        //         return (
        //             <Route exact path={ROUTES.HOME} component={HomePage} />
        //         );
        //     }

        // }




        return (
            <Router>
                <div className="App-wrapper">
                    <div>
                        

                        <Route 
                            exact 
                            path={ROUTES.HOME} 
                            render={(props) => <HomePage {...props} width={this.state.width} height={this.state.height} songs={this.state.songs}/>}
                        />

                        <Route
                            path='/song/:songId'
                            component={SongPage}
                        />

                        <Route path={ROUTES.UPLOAD} component={UploadPage} />
                        <Route path={ROUTES.LOG_IN} component={LoginPage} />
                        <Route path={ROUTES.ADMIN} component={AdminPage} />
                        <Route path={ROUTES.MANAGE} component={ManagePage} />


                        <Route path={ROUTES.CONTACT} component={ContactPage} />
                        <Route 
                            path={ROUTES.ALL_MUSIC} 
                            component={(props) => <MobileAllMusic {...props} songs={this.state.songs}/>} 
                        />

                        <Route 
                            path={ROUTES.GENRES}
                            component={(props) => <MobileGenresPage {...props} genres={this.state.genres}/>}
                        />
                    </div>
                    <MobilePlayer/>
                </div>
            </Router>
        );



    }


}


export default withAuthentication(App);


