import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/index';
import * as serviceWorker from './serviceWorker';
// import {BrowserRouter as Router, Route,Link} from 'react-router-dom';


// MOBX
import { Provider } from 'mobx-react';
import AuthStore from './helpers/authStore'
import PlayerStore from './helpers/playerStore';
import Firebase, { FirebaseContext } from './components/Firebase';



ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <Provider PlayerStore={PlayerStore}>
            <App />
        </Provider>
    </FirebaseContext.Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
