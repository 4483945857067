import React, { Component } from 'react';
import {withFirebase} from '../Firebase/index';

import '../../App.css';



// COMPONENTS
import FolderComponent from '../folder';
import MacWindow from '../macWindow';

import MobileHomePage from '../../pages/mobileHome';



// import FolderComponent from '../components/folder';
// import MacWindow from '../components/macWindow';
import { observer, inject } from 'mobx-react';

@inject('PlayerStore')
@observer
 class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      folderClicked: true,
      width: 0,
      height: 0,
    }

  }


  componentDidMount() {
  }

  componentWillMount() {

  }

  componentWillUnmount() {
  }

  handleFolderClicked = () => {
    this.setState({
      folderClicked: !this.state.folderClicked
    });
  }

  


 


  render() {
    const { PlayerStore } = this.props;

    // const musicSongs = this.props.songs;



    const OptimizedHomePage = () => {
      if (this.props.width == 0) {
        //return loading page

        return (
          <div><h1>loading</h1></div>
        );

      } else if (this.props.width <= 600) {
        return (
          <MobileHomePage songs={this.props.songs} />
        );
      } else {
        return (
          <div className="main-content-container">
            <FolderComponent handleFolderClicked={this.handleFolderClicked} folderClicked={this.state.folderClicked} />
            <MacWindow songs={this.props.songs} handleFolderClicked={this.handleFolderClicked} folderClicked={this.state.folderClicked} />
    
          </div>
        );
      }

    }

    return (
      <OptimizedHomePage/>
    );
  }
}

export default withFirebase(HomePage);





// const songs = [{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},]
