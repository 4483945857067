import React, { Component } from 'react';
// import firebase from "firebase";
// import ReactPlayer from 'react-player'
import {withRouter} from 'react-router-dom';

import '../css/components/mobileHeaderLink.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'




// const songs = [{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},]


// COMPONENTS
// import FolderComponent from '../components/folder';
// import MacWindow from '../components/macWindow';

import { observer, inject } from 'mobx-react';

@inject('PlayerStore')
@observer
export default class MobileHeaderLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      folderClicked: false,
    }
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }


  handleFolderClicked = () => {
    this.setState({
      folderClicked: !this.state.folderClicked
    });
  }




  render() {

    const rightArrow = <FontAwesomeIcon icon={faChevronRight} className="mobile-header-link-icon" />
    const extraClass = !this.props.extraClass ? "" : this.props.extraClass;
    const mainClass = "mobile-header-link " + extraClass;


    const TopBar = () => {
      if (this.props.topBar) {
        return (<div className="mobile-header-link-bar"></div>);
      } else {
        return null
      }
    }

    return (
      <div onClick={this.props.navigateAction} className={mainClass}>
        <div className="mobile-header-link_blank-container"></div>

        <div className="mobile-header-link_content-container">
          <TopBar />
          <div className="mobile-header-link_text-container">
            <div className="mobile-header-link_name-container"><p>{this.props.linkText}</p></div>
            <div className="mobile-header-link_arrow-container">{rightArrow}</div>
          </div>
          <div className="mobile-header-link-bar"></div>
        </div>


      </div>
    );
  }
}


