import React, { Component } from 'react';
import '../../css/pages/upload.css';
import {withAuthorization} from '../Session';
import {message} from "flwww";


class UploadPage extends Component {
  constructor (props) {
    super(props)
    this.state = { 
      name: '',
      genre: '',
      file: null,
      fileUploaded: 0,
      colorCode: null,
    }
  }


  handleNameChange = (e) => {
    this.setState({
      name: e.target.value
    });
  }

  handleGenreChange = (e) => {
    var genre = e.target.value;
    var color = null;

    if(genre == "Hip Hop"){
      color = '#F58b57';
    }else if(genre == "Pop"){
      color = '#ff486d';
    }else if(genre == "Rock"){
      color = '#010203';
    }else if(genre == "Funk"){
      color = '#a400fa';
    }else if(genre == "Blues"){
      color = '#3587A4';
    }else if(genre == "Country"){
      color = '#3c3b6e';
    }else if(genre == "Techno"){
      color = '#40354c';
    }else if(genre == "Reggae"){
      color = 'f9dc5c';
    }else if(genre == "Experimental"){
      color = '#fe0000';
    }else if(genre == "Alternative"){
      color = '#FCF5C7';
    }else if(genre == "Industrial"){
      color = '#c6c7c4';
    }else if(genre == "Gospel"){
      color = '#efbcd5';
    } else{
      color = '#fff';
    }


    this.setState({
      genre: e.target.value,
      colorCode: color,

    });
  }
  
  handleFile = (e) =>{
    this.setState({
        file: e.target.files[0],
    });
  }


  onSubmit = event => {

    this.setState({fileUploaded: 1});

    
    const beat = this.state.file;
    const name = this.state.name;
    const genre = this.state.genre;
    const colorCode = this.state.colorCode;

    this.props.firebase.doBeatUpload(beat)
      .then((doc) => {
        doc.ref.getDownloadURL().then((downloadUrl) => {
          this.props.firebase.doCreateBeatDoc(name, genre, downloadUrl, colorCode)
            .then(() => {
              this.setState({
                name: '',
                genre: '',
                file: null,
                fileUploaded: 2,
                colorCode: null,
                active: true,
              });
            })
            .then(() => {
              this.setState({fileUploaded: 0});
              // alert('Beat Successfully Uploaded');
              message("Upload Successful", "success", 3);
            })
            .catch(error => {
              console.log(error);
            })
        })
        
      })
      .catch(error => {
        console.log(error);
      });

      event.preventDefault();

  }
  

  
  render() {

    

    const SubmitButton = () => {
      if(this.state.fileUploaded === 1){
          return (<button ><span className="btn-load btn-open uploadButton"></span></button>);
      } else if (this.state.fileUploaded === 2){
          return (<button className="upload-button" style={{backgroundColor: '#46fc3e', color: 'black'}}>Uploaded!</button>);
      } else{
          // inital state
          return (<button className="upload-button" style={{backgroundColor: '#46fc3e', color: 'black'}} onClick={this.onSubmit}>Upload this masterpiece</button>);
      }
  };



    return (
      <div className="upload-page">
          <form onSubmit={this.onSubmit}>
            <input 
              placeholder="Name" 
              type="text" 
              onChange={this.handleNameChange} 
              value={this.state.name}
            />

            <select  value={this.state.genre} onChange={this.handleGenreChange}>
                <option value="" disabled>Genre</option>
                <option value="Hip Hop">Hip Hop</option>
                <option value="Pop">Pop</option>
                <option value="Rock">Rock</option>
                <option value="Funk">Funk</option>
                <option value="Blues">Blues</option>
                <option value="Country">Country</option>
                <option value="Techno">Techno</option>
                <option value="Reggae">Reggae</option>
                <option value="Experimental">Experimental</option>
                <option value="Alternative">Alternative</option>
                <option value="Industrial">Industrial</option>
                <option value="Gospel">Gospel</option>
             </select>

             <input 
                className="file-select-input" 
                type="file" 
                name="myfile" 
                onChange={this.handleFile} 
                // value={this.state.file}
              />

              <SubmitButton/>
          </form>

      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(UploadPage);

