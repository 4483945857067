import React, { Component } from 'react';
import '../../App.css';
import {withRouter} from 'react-router-dom';



// COMPONENTS

import MobilePageNav from '../../components/mobilePageNav';
import MobilePageHeader from '../../components/mobilePageHeader';
import MobileHeaderLink from '../../components/mobileHeaderLink';






import { observer, inject } from 'mobx-react';

 class MobileGenresPage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }


    }


    componentDidMount() {

    }

    componentWillMount() {

    }

    componentWillUnmount() {
    }


    navHome = () => {
        this.props.history.push('/');
    }



    render() {

        const genreItems = this.props.genres ? this.props.genres.map((item, i) => <MobileHeaderLink key={i} navigateAction={() => console.log('hello')} topBar={false} linkText={item} />) : null;



     
        return (
            <div className="mobile-genres-page">
                <MobilePageNav currentPageName="Genres" navAction={this.navHome} pageName="Home"/>
                <MobilePageHeader pageTitle="Genres"/>

                {genreItems}




            </div>
        );
    }
}

export default withRouter(MobileGenresPage);





