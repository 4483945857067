import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import firebase from "firebase";
import ReactPlayer from 'react-player'
import {withFirebase} from '../components/Firebase';
// import {withRouter} from 'react-router-dom';
import '../css/pages/mobileHome.css'



// const songs = [{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},{id: 0, name: 'Beat 1',},]


// COMPONENTS
// import FolderComponent from '../components/folder';
// import MacWindow from '../components/macWindow';
import MobileHeaderLink from '../components/mobileHeaderLink';
import MobileMusicItem from '../components/mobileMusicItem';



import { observer, inject } from 'mobx-react';

@inject('PlayerStore')
@observer
 class MobileHomePage extends Component {
  constructor (props) {
    super(props)
    this.state = { 
        folderClicked: false,
        songs:null,

    }
  }

  componentWillMount(){

  }

  componentDidMount(){
    
  }

  componentWillUnmount(){
    
  }


  handleFolderClicked = () => {
    this.setState({
      folderClicked: !this.state.folderClicked
    });
  }


  navToContact = () => {
    this.props.history.push('/contact');
  }

  navAllMusic = () => {
    this.props.history.push('/all-music');
  };

  navGenres = () => {
    this.props.history.push('/genres');
  }




  render() {
    const {PlayerStore} = this.props;

    const musicItems = this.props.songs ? this.props.songs.slice(0,6).map((item, i) => <MobileMusicItem key={i} id={item.id} url={item.url} colorCode={item.colorCode} name={item.name} genre={item.genre}/>) : null;


    return (
      <div className="mobile-content-container">
            <div className="mobile-home-header-container">
                <h1>Mike Made This Beat</h1>
                <MobileHeaderLink navigateAction={this.navAllMusic} topBar={true} linkText="All Music" />
                {/* <MobileHeaderLink navigateAction={this.navGenres} topBar={false} linkText="Genres" /> */}
                {/* <MobileHeaderLink topBar={false} linkText="About" /> */}
                <MobileHeaderLink navigateAction={this.navToContact} topBar={false} linkText="Contact" />
            </div>
            <div className="recently-added-text-container"><h2 className="recently-added-text">Recently Added</h2></div>
            <div className="mobile-music-items-container">{musicItems}</div>
            

      </div>
    );
  }
}


export default withRouter(MobileHomePage);



