import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import '../../../css/components/MobilePlayer/components/artworkContainer.css';





import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faMusic, faForward } from '@fortawesome/free-solid-svg-icons'





@inject('PlayerStore')
@observer
export default class ArtworkContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fullscreen: false,


    }
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }




  render() {

    const { PlayerStore } = this.props;
    // const viewExpanded = !PlayerStore.fileName ? false : true;



    const musicIcon = <FontAwesomeIcon className={this.props.fullscreen ? "artwork-music-icon artwork-music-icon_show" : "artwork-music-icon artwork-music-icon_hidden" } icon={faMusic}/>




    return (
      <div className={this.props.fullscreen ? "artwork-container artworkContainer_show" : "artwork-container artworkContainer_hidden"}>
            <div style={{backgroundColor: PlayerStore.colorCode}} className={this.props.fullscreen ? "artwork artwork_show" : "artwork artwork_hidden" }>
                {musicIcon}
            </div>
      </div>
    );
  }
}

