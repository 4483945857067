import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import '../../../css/components/MobilePlayer/components/closeControl.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'




@inject('PlayerStore')
@observer
export default class CloseControl extends Component {
  constructor(props) {
    super(props)
    this.state = {


    }
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }


  render() {


    const downIcon = <FontAwesomeIcon className="close-control-icon"  icon={faChevronDown}/>; 


    return (
      <div onClick={this.props.handleFullScreen} className={this.props.fullscreen ? "close-control-container close-control-container_show" : "close-control-container close-control-container_hidden"}>{downIcon}</div>
    );
  }
}

