import React, { Component } from 'react';
import '../../css/pages/mobileContact.css';
import {withRouter} from 'react-router-dom';



// COMPONENTS

import MobilePageNav from '../../components/mobilePageNav';
import MobilePageHeader from '../../components/mobilePageHeader';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTwitter  } from '@fortawesome/free-brands-svg-icons';






import { observer, inject } from 'mobx-react';

class MobileContactPage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }


    }


    componentDidMount() {

    }

    componentWillMount() {

    }

    componentWillUnmount() {
    }


    navHome = () => {
        this.props.history.push('/');
    }



    render() {

        const mailIcon = <FontAwesomeIcon className="contact-icon contact-icon_mail" icon={faEnvelope}/>;
        const twitterIcon = <FontAwesomeIcon className="contact-icon contact-icon_twitter" icon={faTwitter}/>;
        const instaIcon = <FontAwesomeIcon className="contact-icon contact-icon_insta" icon={faInstagram}/>;
     
        return (
            <div className="mobile-contact-page">
                <MobilePageNav currentPageName="Contact" navAction={this.navHome} pageName="Home"/>
                <MobilePageHeader pageTitle="Contact"/>


                <div className="contact-method-container">
                    <a href="mailto:mikeydbeatz@gmail.com">{mailIcon}</a>
                    <a href="https://www.twitter.com/MichaelScaros" target="_blank">{twitterIcon}</a>
                    <a href="https://www.instagram.com/michaelscaros/" target="_blank">{instaIcon}</a>
                </div>



            </div>
        );
    }
}

export default withRouter(MobileContactPage);





