import React, { Component } from 'react';
import '../css/components/desktopMusicIcon.css';


// import folderIcon from '../mac-folder-icon.png';
// import musicIcon from '../music-icon-mac.png';

import {inject, observer} from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faMusic } from '@fortawesome/free-solid-svg-icons'

@inject('PlayerStore')
@observer
export default class DesktopMusicItem extends Component {
  constructor (props) {
    super(props)
    this.state = { 
      
    }
  }

  componentWillMount(){

  }

  componentDidMount(){

  }

  componentWillUnmount(){
    
  }


 

  render() {
    const {PlayerStore} = this.props;
    const musicIcon = <FontAwesomeIcon className="desktop-music-item-music-icon" icon={faMusic} />;
    // const name = this.props.name;
    // const url = this.props.url;

    const id = this.props.id;
    const name = this.props.name;
    const url = this.props.url;
    const colorCode = this.props.colorCode;
    

    return (
      <div onClick={() => PlayerStore.handleItemClick(id, name, url, colorCode)} className="desktop-music-icon">
        <div style={{backgroundColor: this.props.colorCode}} className="desktop-music-item-album-cover">
            {musicIcon}
        </div>
        <div className="desktop-music-item-info-container">
            <h4>{this.props.name}</h4>
            <p>{this.props.genre}</p>
        </div>
      </div>
    );
  }
}


