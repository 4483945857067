import React, { Component } from 'react';
// import firebase from "firebase";
// import ReactPlayer from 'react-player'
import '../css/components/mobilePageNav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft} from '@fortawesome/free-solid-svg-icons'







import { observer, inject } from 'mobx-react';

@inject('PlayerStore')
@observer
export default class MobilePageNav extends Component {
  constructor (props) {
    super(props)
    this.state = { 
        folderClicked: false,
        songs:null,
        showName: false,

    }
  }

  componentWillMount(){

  }

  componentDidMount(){
    window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.listenToScroll)

  }


  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
  
    const scrolled = (winScroll / height) * 100;
    console.log('scrolled: ' + scrolled);

    if(scrolled >= 5.5){
      this.setState({showName: true});
    } else {
      this.setState({showName: false});
    }
  
    this.setState({
      theposition: scrolled,
    });
  }


  handleFolderClicked = () => {
    this.setState({
      folderClicked: !this.state.folderClicked
    });
  }




  render() {

      const backArrow = <FontAwesomeIcon onClick={this.props.navAction} icon={faChevronLeft} className="back-arrow"/>

    return (
      <div className="mobile-page-nav">
        <div className="row">{backArrow}<p onClick={this.props.navAction}>{this.props.pageName}</p></div>

        <div className="row">
          <p className={this.state.showName ? "mobile-page-nav-name" : "mobile-page-nav-name mobile-page-nav-name_hidden"}>{this.props.currentPageName}</p>
        </div>

        <div className="row"></div>
      </div>
    );
  }
}


