import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import '../../css/components/MobilePlayer/index.css';
import Swipe from 'react-easy-swipe';
import disableScroll from 'disable-scroll';




import ReactPlayer from 'react-player'
import ArtworkContainer from './components/ArtworkContainer';
import BarInfoContainer from './components/BarInfoContainer';
import MobileTouchControl from './components/MobileTouchControl';
import CloseControl from './components/CloseControl';

import FullScreenControls from './components/FullScreenControls';




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faMusic, faForward } from '@fortawesome/free-solid-svg-icons'
import Player from '../player';





@inject('PlayerStore')
@observer
export default class MobilePlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fullscreen: false,

      loop: true,
      playing: false,
      playbackRate: 1,
      volume: 1,
      
      seeking: false,

      played: 0,
      playedSeconds: 0,
      loaded: 0,
      loadedSeconds: 0,


      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,



    };

  }

  componentWillMount() {

  }

  componentDidMount() {

  
  }

  componentWillUnmount() {
  }

  handleGesure() {
    var swiped = 'swiped: ';
    if (touchendX < touchstartX) {
        alert(swiped + 'left!');
    }
    if (touchendX > touchstartX) {
        alert(swiped + 'right!');
    }
    if (touchendY < touchstartY) {
        alert(swiped + 'down!');
    }
    if (touchendY > touchstartY) {
        alert(swiped + 'left!');
    }
    if (touchendY == touchstartY) {
        alert('tap!');
    }
}


  // playPause = () => {
  //   this.setState({ playing: !this.state.playing })
  // }

  stop = () => {
    this.setState({ url: null, playing: false })
  }

  onPause = () => {
    console.log('onPause')
    this.setState({ playing: false })
  }

  onPlay = () => {
    console.log('onPlay')
    this.setState({ playing: true })
  }

  //   onEnded = () => {
  //     console.log('onEnded')
  //     this.setState({ playing: this.state.loop })
  //   }

  onDuration = (duration) => {
    this.setState({ duration });
  }

  onProgress = state => {
    // console.log('onProgress' + state.played)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState({
        played: state.played,
        playedSeconds: state.playedSeconds,
        loaded: state.loaded,
        loadedSeconds: state.loadedSeconds,
      });
    }
  }

  onSeekChange = (e) => {
    console.log('onSeekChange: ' + e.target.value);
    this.setState({ played: parseFloat(e.target.value) });
  }

  onSeekMouseDown = (e) => {
    this.setState({ seeking: true })
  }

  onSeekMouseUp = (e) => {
    console.log('onSeekMouseUp: ' + e.target.value);
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  ref = player => {
    this.player = player
  }


  handleFullScreen = () => {

    this.setState({
      fullscreen: !this.state.fullscreen
    });

  }

  onSwipeStart = (event) => {
    console.log('Start swiping: y '+ event);
  }

  onSwipeMove = (position, event) => {
    console.log(`Moved ${position.x} pixels horizontally`, event);
    console.log(`Moved ${position.y} pixels vertically`, event);
    if(position.y > 100){
      this.setState({
        fullscreen: false,
      }, function () {});
      
    }
  }

  onSwipeEnd = (event) =>  {
    console.log('End swiping...', event);
  }


  render() {

    const { PlayerStore } = this.props;
    const viewExpanded = !PlayerStore.fileName ? false : true;


    const PlayPauseControl = () => {
      if (this.state.playing) {
        return (<FontAwesomeIcon className="play-pause-control active-icon-color" onClick={() => this.setState({ playing: !this.state.playing })} icon={faPause} />);
      } else {
        return (<FontAwesomeIcon className={!PlayerStore.fileName ? "play-pause-control inactive-icon-color" : "play-pause-control active-icon-color"} onClick={() => this.setState({ playing: !this.state.playing })} icon={faPlay} />);
      }
    }

    const ForwardControl = () => {
      return (
        <FontAwesomeIcon className="play-pause-control active-icon-color" icon={faForward} />
      );
    }


    const AlbumCoverDisplay = () => {
      if (viewExpanded) {
        return (
          <div className="player-music-album-cover-container_active">
            <div className="player-music-album-cover">
              {musicIcon}
            </div>
          </div>
        );
      } else {
        return (
          <div className="player-music-album-cover-container">
            <div className="player-music-album-cover">
              {musicIcon}
            </div>
          </div>
        );
      }
    }

    const musicIcon = <FontAwesomeIcon className="music-player-music-icon" icon={faMusic} />;
    const songTitle = !PlayerStore.fileName ? "Not Playing" : PlayerStore.fileName;
    // const songPlaying= PlayerStore.fileName == null ? false : PlayerStore.playing;
    const songPlaying = PlayerStore.playing == null ? false : PlayerStore.playing;








    return (
      <Swipe allowMouseEvents onSwipeStart={this.onSwipeStart} onSwipeMove={this.onSwipeMove} onSwipeEnd={this.onSwipeEnd}>
      <div id="playerView" className={this.state.fullscreen ? "mobile-player-container mobile-player-container_show" : "mobile-player-container mobile-player-container_hidden"}>
        <CloseControl 
          handleFullScreen={this.handleFullScreen} 
          fullscreen={this.state.fullscreen} 
        />

        <MobileTouchControl 
          handleFullScreen={this.handleFullScreen} 
          fullscreen={this.state.fullscreen} 
        />

        <ArtworkContainer 
          fullscreen={this.state.fullscreen} 
        />

        <BarInfoContainer 
          onPause={() => PlayerStore.onPause()} //onpause
          onPlay={() => PlayerStore.onPlay()} //onplay
          songTitle={songTitle} 
          playing={songPlaying} //playing
          fullscreen={this.state.fullscreen} 
        />





        

        <FullScreenControls
          songTitle={songTitle}


          playing={songPlaying} // playing

          duration={this.state.duration}


          fullscreen={this.state.fullscreen}
          played={this.state.played}
          seeking={this.state.seeking}

          onPause={() => PlayerStore.onPause()} //onpause
          onPlay={() => PlayerStore.onPlay()} //onplay
          onSeekMouseDown={this.onSeekMouseDown}
          onSeekChange={this.onSeekChange}
          onSeekMouseUp={this.onSeekMouseUp}
        />



        <ReactPlayer
          ref={this.ref}
          className="react-player-hidden"
          url={PlayerStore.fileUrl}
          playing={songPlaying} // playing
          loop={false}

          onPlay={() => PlayerStore.onPlay()} //onplay
          onPause={() => PlayerStore.onPause()} //onpause
          onProgress={this.onProgress}
          onDuration={this.onDuration}

          playbackRate={1}
          volume={1}
          controls={false}

        />



      </div>
      </Swipe>
    );
  }
}

