import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import '../../../css/components/MobilePlayer/components/mobileTouchControl.css';






@inject('PlayerStore')
@observer
export default class MobilePlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {


    }
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }


  render() {
    const {PlayerStore} = this.props;

    // const songPlaying = PlayerStore.fileName == null ?  : PlayerStore.playing;


    return (
      <div onClick={PlayerStore.fileName == null ? {} : this.props.handleFullScreen} className={this.props.fullscreen ? "mobile-touch-control mobile-touch-control_hidden" : "mobile-touch-control mobile-touch-control_show"}></div>
    );
  }
}

