import React, { Component } from 'react';
import '../../css/pages/admin.css';
import {withAuthorization} from '../Session';
import SignOutButton from '../SignOut/index';
import * as ROUTES from '../../constants/routes';


class AdminPage extends Component {
  constructor (props) {
    super(props)
  }

  render() {
    return (
      <div className="admin-page">
        <button onClick={() => this.props.history.push(ROUTES.HOME)} className="admin-page-button white-button">HOME</button>
        <button onClick={() => this.props.history.push(ROUTES.UPLOAD)} className="admin-page-button green-button">UPLOAD</button>
        <button onClick={() => this.props.history.push(ROUTES.MANAGE)} className="admin-page-button blue-button">MANAGE BEATS</button>
        <SignOutButton/>
      </div>
    );
  }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(AdminPage);